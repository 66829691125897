import { DispatchTruck } from './dispatch-truck';
import { TripStatus } from './trip-status';

export class DispatchTrip {
  constructor(id?: number) {
    this.id = id;
  }

  id: number;
  status: TripStatus;
  startCity: string;
  startState: string;
  endCity: string;
  endState: string;
  scheduledStartDateTime: Date;
  scheduledEndDateTime: Date;
  actualStartDateTime?: Date;
  actualEndDateTime?: Date;
  pickupCount: number;
  deliveryCount: number;
  truck: DispatchTruck;
  loadNumbers: Array<string>;
  isMissingDetails: boolean;
}
