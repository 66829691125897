export const SET_DISPLAYED_LOADS = 'SET_DISPLAYED_LOADS';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_SORT_FILTER = 'SET_SORT_FILTER';
export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const SET_TEXT_FILTER = 'SET_TEXT_FILTER';
export const SET_PAGE_FILTER = 'SET_PAGE_FILTER';
export const SET_SUMMARY = 'SET_SUMMARY';
export const SET_DISPLAY_LOADING_INDICATOR = 'SET_DISPLAY_LOADING_INDICATOR';
export const SET_LOAD_SELECTED = 'SET_LOAD_SELECTED';
export const SET_PAGE_LOADS_SELECTED = 'SET_PAGE_LOADS_SELECTED';
export const UNSELECT_ALL_LOADS = 'UNSELECT_ALL_LOADS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const ADD_PLACEHOLDER_LOAD = 'ADD_PLACEHOLDER_LOAD';
export const SET_LOAD_CREATED = 'SET_LOAD_CREATED';
export const RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS';
export const ADD_LOAD_FOR_INVOICING = 'ADD_ADVANCED_LOAD_FOR_INVOICING';
export const SET_DOWNLOAD_FLAG = 'SET_ADVANCED_DOWNLOAD_FLAG';
export const ADD_FINISHED_INVOICE_STATUS = 'ADD_ADVANCED_FINISHED_INVOICE_STATUS';
export const CLEAR_FINISHED_INVOICE_STATUS = 'CLEAR_ADVANCE_FINISHED_INVOICE_STATUS';
export const CLEAR_LOAD_FOR_INVOICING = 'CLEAR_ADVANCE_LOAD_FOR_INVOICING';
export const REFRESH_LOAD_FOR_INVOICING = 'REFRESH_ADVANCED_LOAD_FOR_INVOICING';
export const SET_LOAD_SNACKBAR_CONFIG = 'SET_ADVANCE_LOAD_SNACKBAR_CONFIG';
export const SET_LOAD_MESSAGE_STATUS = 'SET_ADVANCE_LOAD_MESSAGE_STATUS';
export const SET_PAGE_SIZE_FILTER = 'SET_PAGE_SIZE_FILTER';
export const SET_BILLING_DESCRIPTIONS = 'SET_BILLING_DESCRIPTIONS';
export const SET_BILLING_RATE_TYPES = 'SET_BILLING_RATE_TYPES';
export const SET_BILLING_ITEMS = 'SET_BILLING_ITEMS';
export const SET_BILLING_UPDATES = 'SET_BILLING_UPDATES';
export const SET_BILLING_INVALID_ITEMS = 'SET_BILLING_INVALID_ITEMS';
export const SET_BILLING_LOADING = 'SET_BILLING_LOADING';
