import { DriverSchedule } from '../../interfaces/driver-schedule';
import * as DispatchConstants from '../../constants/dispatchConstants';
import { DispatchDriver } from '../../interfaces/dispatch-driver';
import { addDays, getMonday, getSunday } from '../../components/dispatch/dispatch-utils';
import { DispatchTripItem } from '../../interfaces/dispatch-trip-item';
import { DispatchTripDetails } from '../../interfaces/dispatch-trip-details';

interface DispatchStoreState {
  startDate: Date;
  endDate: Date;
  drivers: DispatchDriver[];
  startDateBoundary: Date;
  endDateBoundary: Date;
  driverSchedules: DriverSchedule[];
  filteredDriverSchedules: DriverSchedule[];
  searchCriteria: string;
  isLoading: boolean;
  isAssignTripsOpen: {
    isOpen: boolean;
    openingFrom: '' | 'driverScheduleAssign' | 'tripDetailsAssign' | 'tripListAssign';
  };
  tripsInBackwardTimeFrame: boolean;
  tripsInForwardTimeFrame: boolean;
  tripId: number;
  tripDetails: DispatchTripDetails;
  tripList: DispatchTripItem[];
  filteredTripList: DispatchTripItem[];
  searchCriteriaTripList: string;
  tripDetailSidePanelOpen: {
    isOpen: boolean;
    openingFrom: '' | 'driverScheduleDetails' | 'tripListDetails';
  };
  isAssignmentPanelLoading: boolean;
  assignmentPanelDrivers: DriverSchedule[];
}

const initialState: DispatchStoreState = {
  drivers: [],
  startDate: getMonday(new Date()),
  endDate: getSunday(new Date()),
  startDateBoundary: getMonday(addDays(new Date(), -14)),
  endDateBoundary: getSunday(addDays(new Date(), 14)),
  driverSchedules: [],
  filteredDriverSchedules: [],
  searchCriteria: '',
  isLoading: false,
  isAssignTripsOpen: { isOpen: false, openingFrom: '' },
  tripsInBackwardTimeFrame: false,
  tripsInForwardTimeFrame: false,
  tripId: null,
  tripDetails: null,
  tripList: [],
  filteredTripList: [],
  searchCriteriaTripList: '',
  tripDetailSidePanelOpen: { isOpen: false, openingFrom: '' },
  isAssignmentPanelLoading: false,
  assignmentPanelDrivers: [],
};

function sortDriverSchedules(schedules: DriverSchedule[]) {
  return schedules?.slice().sort((a, b) => {
    const nameA = DispatchDriver.getDriverName(a.driver).toLowerCase();
    const nameB = DispatchDriver.getDriverName(b.driver).toLowerCase();
    return nameA.localeCompare(nameB);
  });
}

export default function dispatchReducer(state = initialState, action: any) {
  switch (action.type) {
    case DispatchConstants.SET_START_DATE:
      return {
        ...state,
        startDate: action.startDate,
      };
    case DispatchConstants.SET_END_DATE:
      return {
        ...state,
        endDate: action.endDate,
      };
    case DispatchConstants.SET_DRIVER_SCHEDULES:
      return {
        ...state,
        driverSchedules: sortDriverSchedules(action.schedules),
      };
    case DispatchConstants.SET_FILTERED_DRIVER_SCHEDULES:
      return {
        ...state,
        filteredDriverSchedules: sortDriverSchedules(action.schedules),
      };
    case DispatchConstants.SET_SEARCH_CRITERIA:
      return {
        ...state,
        searchCriteria: action.searchCriteria,
      };
    case DispatchConstants.SET_DRIVERS:
      return {
        ...state,
        drivers: action.drivers,
      };
    case DispatchConstants.TRIPS_EXIST_ON_NEXT_WEEK:
      return {
        ...state,
        tripsInForwardTimeFrame: action.value,
      };
    case DispatchConstants.TRIPS_EXIST_ON_PREVIOUS_WEEK:
      return {
        ...state,
        tripsInBackwardTimeFrame: action.value,
      };
    case DispatchConstants.TRIPS_EXIST_ON_NEXT_PREVIOUS_WEEK:
      return {
        ...state,
        tripsInBackwardTimeFrame: action.values.backward,
        tripsInForwardTimeFrame: action.values.forward,
      };
    case DispatchConstants.SET_TRIP_LIST:
      return {
        ...state,
        tripList: action.sortedTrips,
      };
    case DispatchConstants.SET_FILTERED_TRIP_LIST:
      return {
        ...state,
        filteredTripList: action.sortedTrips,
      };
    case DispatchConstants.SET_DISPATCH_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case DispatchConstants.SET_DISPATCH_ASSIGN_TRIPS_OPEN:
      return {
        ...state,
        isAssignTripsOpen: {
          isOpen: action.payload.isOpen,
          openingFrom: action.payload.openingFrom,
        },
      };
    case DispatchConstants.SET_TRIP_DETAILS:
      return {
        ...state,
        tripDetails: action.values.trip,
      };
    case DispatchConstants.SET_SEARCH_CRITERIA_TRIP_LIST:
      return {
        ...state,
        searchCriteriaTripList: action.searchCriteriaTripList,
      };
    case DispatchConstants.SET_TRIP_ID:
      return {
        ...state,
        tripId: action.tripId,
      };
    case DispatchConstants.UPDATE_STOP:
      return {
        ...state,
        tripDetails: {
          ...state.tripDetails,
          loadDetails: {
            ...state.tripDetails.loadDetails,
            stops: state.tripDetails.loadDetails.stops.map((existingStop) =>
              existingStop.id === action.values.stop.id ? { ...existingStop, ...action.values.stop } : existingStop,
            ),
          },
        },
      };
    case DispatchConstants.SET_TRIP_DETAIL_SIDE_PANEL_OPEN:
      return {
        ...state,
        tripDetailSidePanelOpen: { isOpen: action.payload.isOpen, openingFrom: action.payload.openingFrom },
      };
    case DispatchConstants.SET_TRIP_IS_LATE:
      return {
        ...state,
        filteredTripList: state.filteredTripList.map((trip) =>
          trip.id === action.tripId ? { ...trip, isLate: action.isLate } : trip,
        ),
      };
    case DispatchConstants.SET_ASSIGNMENT_PANEL_LOADING:
      return {
        ...state,
        isAssignmentPanelLoading: action.isLoading,
      };
    case DispatchConstants.SET_ASSIGNMENT_PANEL_DRIVERS:
      return {
        ...state,
        assignmentPanelDrivers: action.assignmentDrivers,
      };
    default:
      return state;
  }
}
