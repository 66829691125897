// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".snackbar__error___1m5lZ div {\n  background-color: rgb(211, 47, 47) !important;\n  font-size: 15px;\n  font-weight: 800;\n}\n.snackbar__error___1m5lZ div svg {\n  color: white;\n}\n\n#snackbar__messageId___3mya9 {\n  font-size: 15px;\n  font-weight: 800;\n}\n\n.snackbar__snackbarButton___3A1i6 {\n  font-weight: 900 !important;\n  color: greenyellow !important;\n}\n\n.snackbar__white___3ZNKX {\n  color: white !important;\n}", "",{"version":3,"sources":["/codebuild/output/src3728986651/src/src/components/core/styles/snackbar.scss","snackbar.scss"],"names":[],"mappings":"AAAA;EACE,6CAAA;EACA,eAAA;EACA,gBAAA;ACCF;ADCE;EACE,YAAA;ACCJ;;ADGA;EACE,eAAA;EACA,gBAAA;ACAF;;ADGA;EACE,2BAAA;EACA,6BAAA;ACAF;;ADGA;EACE,uBAAA;ACAF","file":"snackbar.scss","sourcesContent":[".error div {\n  background-color: rgb(211, 47, 47) !important;\n  font-size: 15px;\n  font-weight: 800;\n\n  svg {\n    color: white;\n  }\n}\n\n#messageId {\n  font-size: 15px;\n  font-weight: 800;\n}\n\n.snackbarButton {\n  font-weight: 900 !important;\n  color: greenyellow !important;\n}\n\n.white {\n  color: white !important;\n}\n",".error div {\n  background-color: rgb(211, 47, 47) !important;\n  font-size: 15px;\n  font-weight: 800;\n}\n.error div svg {\n  color: white;\n}\n\n#messageId {\n  font-size: 15px;\n  font-weight: 800;\n}\n\n.snackbarButton {\n  font-weight: 900 !important;\n  color: greenyellow !important;\n}\n\n.white {\n  color: white !important;\n}"]}]);
// Exports
exports.locals = {
	"error": "snackbar__error___1m5lZ",
	"messageId": "snackbar__messageId___3mya9",
	"snackbarButton": "snackbar__snackbarButton___3A1i6",
	"white": "snackbar__white___3ZNKX"
};
module.exports = exports;
