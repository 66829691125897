// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".notification-dialog__container___xybZ_ > div > div > div:nth-child(2) {\n  font-weight: bold;\n}\n.notification-dialog__container___xybZ_ > div > div > div:nth-child(3) {\n  line-height: 20px;\n}\n.notification-dialog__container___xybZ_ > div > div > div:nth-child(4) > div > button {\n  font-weight: bold;\n}", "",{"version":3,"sources":["/codebuild/output/src3728986651/src/src/components/core/styles/notification-dialog.scss","notification-dialog.scss"],"names":[],"mappings":"AAIQ;EACI,iBAAA;ACHZ;ADOQ;EACI,iBAAA;ACLZ;ADUY;EACI,iBAAA;ACRhB","file":"notification-dialog.scss","sourcesContent":[".container {\n    >div>div {\n\n        // Title\n        >div:nth-child(2) {\n            font-weight: bold;\n        }\n\n        // Message\n        >div:nth-child(3) {\n            line-height: 20px;\n        }\n\n        // Actions\n        >div:nth-child(4){\n            >div > button{\n                font-weight: bold;\n            }\n        }\n    }\n}",".container > div > div > div:nth-child(2) {\n  font-weight: bold;\n}\n.container > div > div > div:nth-child(3) {\n  line-height: 20px;\n}\n.container > div > div > div:nth-child(4) > div > button {\n  font-weight: bold;\n}"]}]);
// Exports
exports.locals = {
	"container": "notification-dialog__container___xybZ_"
};
module.exports = exports;
