import { TFunction } from 'i18next';

export enum LoadStatus {
  All = 0,
  Booked = 1,
  InTransitOnTime = 2,
  InTransitLate = 3,
  Delivered = 4,
  ReadyToBill = 5,
  Invoiced = 6,
  Paid = 7,
  Cancelled = 8,
  Tonu = 9,
}

export const getStatusList = (isEnterprise: boolean = true) =>
  Object.values(LoadStatus)
    .filter((value) => isNaN(Number(value)) && filterStatusByRole(value.toString(), isEnterprise))
    .map((status) => status.toString());

export const getStatusName = (status: string, t: TFunction<'translation'>) => {
  switch (status) {
    case 'All':
      return t('all');
    case 'InTransitOnTime':
      return t('In-Transit On Time');
    case 'InTransitLate':
      return t('In-Transit Late');
    case 'Delivered':
      return t('Delivered');
    case 'ReadyToBill':
      return t('Ready to Bill');
    case 'Invoiced':
      return t('Invoiced');
    case 'Paid':
      return t('Paid');
    case 'Cancelled':
      return t('Cancelled');
    case 'Tonu':
      return t('TONU');
    default:
      return t('Booked');
  }
};

export const getStatusValue = (status: string): number | undefined => {
  const statusEnum = LoadStatus;
  for (const key in statusEnum) {
    if (statusEnum[key] === status) {
      return parseInt(key, 10);
    }
  }
  return undefined;
};

const filterStatusByRole = (status: string, isEnterprise: boolean) => {
  switch (status) {
    case 'All':
      return true;
    case 'Booked':
      return true;
    case 'InTransitOnTime':
      return isEnterprise;
    case 'InTransitLate':
      return isEnterprise;
    case 'Delivered':
      return isEnterprise;
    case 'ReadyToBill':
      return isEnterprise;
    case 'Invoiced':
      return true;
    case 'Paid':
      return true;
    case 'Cancelled':
      return isEnterprise;
    case 'Tonu':
      return isEnterprise;
    default:
      return false;
  }
};
